

function login(user, pass) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        // console.log(this.responseText);
        var errorElem = document.getElementById('loginError');

        if( this.responseText ) {
            var response = JSON.parse(this.responseText);
            // console.log(response);

            errorElem.classList.add('d-none');
            errorElem.classList.remove('d-block');

            if( response.success ) {

                var loc = window.location;
                var dest = loc.protocol + '//' + loc.hostname + loc.pathname + response.success;
                // console.log(dest);
                window.location = dest;
                return;
            }

            if( response.error ) {
                var errorText = '<ul>';

                for( var i = 0; i < response.error.length; i++ ) {
                    errorText += '<li>' + response.error[i] + '</li>';
                }

                errorText += '</ul>';
                errorElem.innerHTML = errorText;
                    // console.log(errorText);

                errorElem.classList.remove('d-none');
                errorElem.classList.add('d-block');
            }
        }
    };
    xhttp.open("GET", "inc/login.php?user="+user+"&pass="+pass, true);
    xhttp.send();
}

document.addEventListener('DOMContentLoaded', function(event) {
    // console.log(window.location);

        var loginSubmit = document.getElementById('loginSubmit'),
            popupLoginUser = document.getElementById('popupLoginUser'),
            popupLoginPass = document.getElementById('popupLoginPass'),
            user = '',
            pass = '';

    loginSubmit.addEventListener('click', function(event) {
        event.preventDefault();

        // console.log('login clicked');

        if( popupLoginUser ) {
            user = popupLoginUser.value;
            // console.log(user);
        }

        if( popupLoginPass ) {
            pass = popupLoginPass.value;
            // console.log(pass);
        }

        login( user, pass );
    });

    popupLoginUser.addEventListener("keyup", event => {
        // console.log(event.keyCode);

        if (event.keyCode === 13) {
            loginSubmit.click();
        }
    });

    popupLoginPass.addEventListener("keyup", event => {
        // console.log(event.keyCode);

        if (event.keyCode === 13) {
            loginSubmit.click();
        }
    });
});
