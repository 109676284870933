document.addEventListener('DOMContentLoaded', function(event) {

    var isPopupOpen = false,
        popup = document.getElementById('popup'),
        popupError = document.getElementById('popupError'),
        popupLogin = document.getElementById('popupLogin'),
        popupLoginButton = document.getElementById('popupLoginButton'),
        loginForgot = document.getElementById('loginForgot'),
        loginForgotClue = document.getElementById('loginForgotClue'),
        popupTimeout = 3000;

    setTimeout(function(){
        openPopup();
    }, popupTimeout);

    function openPopup(){

        if( isPopupOpen === false ) {
            isPopupOpen = true;
            popup.classList.remove('d-none');
            popup.classList.add('d-flex');
            document.body.style.overflow = 'hidden';
            popupLoginUser.focus();
        }
    }

    var links = document.getElementsByClassName('read-more');

    var linkClick = function(event) {
        event.preventDefault();
        openPopup();
    };

    for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', linkClick, false);
    }

    popupLoginButton.addEventListener('click', function(event) {
        event.preventDefault();

        var popupLoginUser = document.getElementById('popupLoginUser');
        popupError.classList.add('d-none');

        popupLogin.classList.remove('d-none');
        popupLogin.classList.add('d-block');

        popupLoginUser.focus();
    });

    loginForgot.addEventListener('click', function(event) {
        event.preventDefault();
        loginForgotClue.classList.remove('d-none');
        loginForgotClue.classList.add('d-block');
    });
});
